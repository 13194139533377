:root {
  --color-corporativo: #0482c4 !important;
  --color-corporativo2: #f19632 !important;
}

.lds-grid div {
  background: #0482c4 !important;
}

.ticket-nuevo .btn-aceptar {
  color: #0482c4 !important;
}


.Logotipo {
  display: block;
  width: 90%;
  height: auto;
  max-width: 400px;
  margin: auto;
  padding-top: 42px;
  padding-bottom: 10px;
}

.LogotipoHome {
  display: block;
  width: 90%;
  height: auto;
  max-width: 400px;
  margin: auto;
  padding-top: 42px;
  padding-bottom: 10px;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid #0482c4 !important;
  outline: none;
}

.icono-linea-sms:focus,
.icono-linea-sms:active,
.icono-linea-sms:visited,
.icono-linea-sms:hover,
.icono-linea-sms:focus-visible {
  border: 1px solid black !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-llama:focus,
.icono-linea-llama:active,
.icono-linea-llama:visited,
.icono-linea-llama:hover,
.icono-linea-llama:focus-visible {
  border: 1px solid #0482c4 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-gb:focus,
.icono-linea-gb:active,
.icono-linea-gb:visited,
.icono-linea-gb:hover,
.icono-linea-gb:focus-visible {
  border: 1px solid #f19632 !important;
  border-radius: 20px;
  outline: none;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid #008FF5 !important;
  outline: none;
}

.icono-linea:focus,
.icono-linea:active,
.icono-linea:visited,
.icono-linea:hover,
.icono-linea:focus-visible {
  border: 1px solid #008FF5 !important;
  border-radius: 20px;
  outline: none;
}

.num-contrato {
  color: var(--color-corporativo) !important;
}

.ul.list-unstyled li {

  border: 1px solid lightgray !important;

}

ion-fab-button {
  --background: #b7f399;
  --background-activated: #87d361;
  --background-hover: #a3e681;
  --border-radius: 15px;
  --box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
  --color: black;

}

ion-fab-button {
  width: 150px;
  font-size: medium;

}